import { colorPalette } from "gx-npm-common-styles";

const productUnderConsidersationStyles = {
  root: {
    "& .gx-product-name": { paddingLeft: 16 },
    "& .gx-survey-instructions": {
      marginTop: 8,
      whiteSpace: "break-spaces",
      wordBreak: "break-word",
    },

    backgroundColor: colorPalette.basic.white.hex,
    padding: 32,
    borderRadius: "8px",
    minHeight: 260,
  },
  logoPtagWrapper: { display: "flex", alignItems: "center", marginTop: 12, marginBottom: 40 },
};

export default productUnderConsidersationStyles;
