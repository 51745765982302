// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RF8NaCPgozRqcc1ZGnnf{margin-top:32px;max-width:600px}@media(max-width: 1440px){.RF8NaCPgozRqcc1ZGnnf{max-width:620px}}@media(max-width: 1240px){.RF8NaCPgozRqcc1ZGnnf{max-width:683px}}@media(max-width: 1024px){.RF8NaCPgozRqcc1ZGnnf{max-width:572px}}.RF8NaCPgozRqcc1ZGnnf .OdzFFBIHeXYtNcgtvhcS{margin-top:16px}.oLWc51Nj7ZWcoRq3Mdmb{margin-top:32px}.a4SyOCtADhcFrIa_0lob{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/ui/survey-progress-information/quick-start/data-privacy/data-privacy.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,eAAA,CACA,0BAHF,sBAII,eAAA,CAAA,CAEF,0BANF,sBAOI,eAAA,CAAA,CAEF,0BATF,sBAUI,eAAA,CAAA,CAEF,4CACE,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".description {\n  margin-top: 32px;\n  max-width: 600px;\n  @media (max-width: 1440px) {\n    max-width: 620px\n  }\n  @media (max-width: 1240px) {\n    max-width: 683px\n  }\n  @media (max-width: 1024px) {\n    max-width: 572px\n  }\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `RF8NaCPgozRqcc1ZGnnf`,
	"additionalInfo": `OdzFFBIHeXYtNcgtvhcS`,
	"helpLink": `oLWc51Nj7ZWcoRq3Mdmb`,
	"loader": `a4SyOCtADhcFrIa_0lob`
};
export default ___CSS_LOADER_EXPORT___;
