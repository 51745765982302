// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WmD9vpFx15b_meAFQH5u{margin-top:24px}@media(max-width: 1440px){.MBatUxRV0MtzDVgsj_J2{width:638px}}@media(max-width: 1240px){.MBatUxRV0MtzDVgsj_J2{width:683px}}@media(max-width: 1024px){.MBatUxRV0MtzDVgsj_J2{width:572px}}.Io8kxOk9Pn74gQevui_A{margin-top:32px;max-width:600px}@media(max-width: 1440px){.Io8kxOk9Pn74gQevui_A{max-width:620px}}@media(max-width: 1240px){.Io8kxOk9Pn74gQevui_A{max-width:683px}}@media(max-width: 1024px){.Io8kxOk9Pn74gQevui_A{max-width:572px}}.Io8kxOk9Pn74gQevui_A .KBppt6ndcfLEZwoEZPdv{margin-top:16px}.oPSH68RS2OIdefTN6Jg8{margin-top:32px}.uTdl8IDIyZHApeTeD9zI{position:absolute;width:450px;height:200px}`, "",{"version":3,"sources":["webpack://./src/ui/survey-progress-information/quick-start/sharing-responses/sharing-responses.styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,0BACE,sBACE,WAAA,CAAA,CAIJ,0BACE,sBACE,WAAA,CAAA,CAIJ,0BACE,sBACE,WAAA,CAAA,CAIJ,sBACE,eAAA,CACA,eAAA,CACA,0BAHF,sBAII,eAAA,CAAA,CAEF,0BANF,sBAOI,eAAA,CAAA,CAEF,0BATF,sBAUI,eAAA,CAAA,CAEF,4CACE,eAAA,CAIJ,sBACE,eAAA,CAGF,sBACE,iBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".sharingResponsesImageContainer {\n  margin-top: 24px;\n}\n\n@media (max-width: 1440px) {\n  .imgSize {\n    width: 638px;\n  }\n}\n\n@media (max-width: 1240px) {\n  .imgSize {\n    width: 683px;\n  }\n}\n\n@media (max-width: 1024px) {\n  .imgSize {\n    width: 572px;\n  }\n}\n\n.description {\n  margin-top: 32px;\n  max-width: 600px;\n  @media (max-width: 1440px) {\n    max-width: 620px\n  }\n  @media (max-width: 1240px) {\n    max-width: 683px\n  }\n  @media (max-width: 1024px) {\n    max-width: 572px\n  }\n  .additionalInfo {\n    margin-top: 16px;\n  }\n}\n\n.helpLink {\n  margin-top: 32px;\n}\n\n.loader {\n  position: absolute;\n  width: 450px;\n  height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sharingResponsesImageContainer": `WmD9vpFx15b_meAFQH5u`,
	"imgSize": `MBatUxRV0MtzDVgsj_J2`,
	"description": `Io8kxOk9Pn74gQevui_A`,
	"additionalInfo": `KBppt6ndcfLEZwoEZPdv`,
	"helpLink": `oPSH68RS2OIdefTN6Jg8`,
	"loader": `uTdl8IDIyZHApeTeD9zI`
};
export default ___CSS_LOADER_EXPORT___;
