import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import productUnderConsiderationStyles from "./product-under-consideration.styles";
import { ProductLogo, TypographyComponent } from "gx-npm-ui";
import { AppContext } from "../../app.context";
import { useTranslation } from "react-i18next";
import PreviewProductLogo from "../../assets/images/preview-product-logo.svg";
import classNames from "classnames";

const useStyles = makeStyles(productUnderConsiderationStyles);

const ProductUnderConsideration = () => {
  const classes = useStyles();
  const { productName, imageLoc, isPreviewMode, surveyInstructions } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.root)}>
      <TypographyComponent styling={"p4"} boldness={"regular"} color={"iron"}>
        {t("Product under consideration")}
      </TypographyComponent>
      <div className={classes.logoPtagWrapper}>
        <ProductLogo logo={isPreviewMode ? PreviewProductLogo : imageLoc} imageWidth={"32px"} imageHeight={"32px"} />

        <TypographyComponent rootClassName={"gx-product-name"} color={"carbon"} styling={"p2"} boldness={"medium"}>
          {productName}
        </TypographyComponent>
      </div>
      <TypographyComponent color={"iron"} styling={"p4"} boldness={"regular"}>
        {t("Questionnaire Instructions")}
      </TypographyComponent>
      <TypographyComponent color={"coal"} boldness={"regular"} rootClassName={"gx-survey-instructions"} styling={"p3"}>
        {surveyInstructions}
      </TypographyComponent>
    </div>
  );
};

export default ProductUnderConsideration;
